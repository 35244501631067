// Header

.header--wrapper {
  width     : 100vw;
  background: white;
  position  : fixed;
  z-index   : 2;
  box-shadow: 0 0 0.75rem black;
}

.header--left {
  order  : 0;
  padding: 0 2rem;

  .header--login {
    font-size: 1.6rem;
  }
}

.header--right {
  display    : flex;
  align-items: center;
  order      : 2;

  a {
    font-size: 1.6rem;
    padding  : 0 2rem;
  }
}

.header--logo {
  border: 0;

  img {
    height: 4rem;
  }
}

.header--menu {
  order : 1;
  margin: auto;
  height: 100%;

  >ul {
    display        : flex;
    align-items    : stretch;
    height         : 100%;
    list-style-type: none;
    padding        : 0;

    li {
      text-decoration: none;
      display        : block;
      //padding      : 3rem 0 0 0;
      width          : 10rem;
      display        : flex;
      align-items    : center;
      justify-content: center;
      text-align     : center;
      font-size      : 1.6rem;
      cursor         : pointer;

      &:hover {
        color: var(--color-desire);

        >a {
          color: var(--color-desire) !important;
        }
      }

      a {
        width          : 100%;
        height         : 100%;
        display        : flex;
        align-items    : center;
        justify-content: center;
      }
    }
  }
}

// Main

.video__wrapper {
  position: absolute;
  overflow: hidden;
  width   : 100%;
  height  : 100%;
}

.main--video {
  position  : absolute;
  z-index   : 0;
  top       : -9999px;
  right     : -9999px;
  bottom    : -9999px;
  left      : -9999px;
  margin    : auto;
  min-width : 100%;
  min-height: 100%;
}

.video-overlay {
  width     : 100%;
  height    : 100%;
  position  : absolute;
  background: linear-gradient(15deg, rgba(255, 0, 0, 0.6), rgba(102, 102, 102, 0.7));
}

.main--text {
  position  : absolute;
  z-index   : 1;
  text-align: center;

  span {
    display: block;
    height : 2rem;
  }

  &__header {
    font-size         : 5rem;
    animation-name    : moveInLeft;
    animation-duration: 3s;
    transition-delay  : 4s;
    letter-spacing    : 1.2rem;
    font-weight       : 400;
    margin-bottom     : 0.8rem;
  }

  &__quote {
    letter-spacing    : 1rem;
    font-size         : 2rem;
    animation-name    : moveInLeft;
    animation-duration: 3s;
    transition-delay  : 4s;
  }

  &__price {
    font-size         : 3rem;
    animation-name    : moveInRight;
    animation-duration: 3s;
    transition-delay  : 4s;
  }
}

.main--text__cond {
  font-size         : 1.5rem;
  animation-name    : moveInRight;
  animation-duration: 3s;
  transition-delay  : 4s;
  text-decoration   : none;

  &:link,
  :visited,
  :active {
    text-decoration: none;
    color          : var(--color-white);
  }

  &:hover {
    text-decoration: underline;
  }
}

.main--btn__order {
  background: var(--color-white);
  color     : var(--color-blue-horizon);

  &:hover {
    color     : var(--color-fusion-red);
    transform : translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    outline   : 0;
  }

  &:active,
  :focus {
    transform : translateY(-1px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    border    : 0;
  }

  &:link,
  :visited {
    display            : block;
    height             : 5rem;
    width              : 30rem;
    margin             : 0 auto;
    border-radius      : 100px;
    padding            : 8px 0 0 0;
    animation          : moveInBottom 3s ease-out 0.75s;
    animation-fill-mode: backwards;
    text-decoration    : none;
    text-transform     : uppercase;
    transition         : all 0.2s;
    border             : 0;
  }
}

.sec-header {
  font-size      : 3rem;
  text-transform : uppercase;
  letter-spacing : 0.3rem;
  width          : 100%;
  text-align     : center;
  margin         : 0 0 4rem 0;
  justify-content: center;
  color          : var(--color-blue-horizon);
  padding        : 2rem 0 0 0;
}

.sec-header--line {
  width     : 17rem;
  height    : 0.3rem;
  margin    : 0 auto;
  background: var(--color-blue-horizon);
  padding   : 0.2rem 0 0 0;
}

.sec-service--item {
  max-width: 57rem;
  margin   : 4rem 0 6rem 0;
  display  : flex;
  flex-flow: wrap;
}

.sec-service--img {
  height: 5rem;
  flex  : 1;
  fill  : currentColor;
}

.sec-service--header {
  flex          : 8;
  width         : 100%;
  font-size     : 2.4rem;
  margin        : 0 0 0 3rem;
  vertical-align: top;
  padding       : 0;
}

.sec-service--text {
  display  : inline-flex;
  width    : 100%;
  font-size: 1.7rem;
  margin   : 2rem 0 0 0;
}

.sec-service--line {
  border-bottom: 1px solid #999;
  width        : 100%;
  display      : inline-flex;
}

// Order Form
//.sec-order-form{
//width : 70rem;
//margin: 2rem 0 2rem 0;
//}

label {
  flex  : 1;
  margin: 2rem 0 2rem 0;
}

input {
  width        : 100%;
  margin       : 1rem 0 1rem 0;
  height       : 3.5rem;
  border-radius: 0.7rem;
}

textarea {
  width: 100%;
}

.label-order {
  display        : flex;
  justify-content: center;
}

.btn-order {
  width: 20rem;
}

// Footer

.sec--pageroot__menu {}

// Scroll Button

.scroll-top-btn {
  width        : 5rem;
  height       : 5rem;
  position     : fixed;
  z-index      : 9;
  bottom       : 9rem;
  right        : 2rem;
  background   : rgba(0, 0, 0, 0.5);
  color        : white;
  border       : 0;
  border-radius: 50%;
  outline      : 0;
  cursor       : pointer;
  opacity      : 0;
  visibility   : hidden;
  transition   : 250ms;
}

// Footer

.footer__bottom {
  padding   : 1rem;
  border-top: 0.125rem solid var(--color-azure);
  width     : 100%;
}

.footer__links {
  padding: 2rem 1rem;
  width  : 100%;

  .links--right {
    text-align: right;
  }

  a {
    display  : block;
    color    : white;
    font-size: 1.5rem;
  }
}

// Sticky Footer
.wrap {
  min-height    : 100vh;
  display       : flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer--back {
  flex-shrink: 0;
}

// Jobs

.jobs {
  padding-top  : 5%;
  margin-bottom: 5%;
}

.jobs-title {
  font-size     : 4rem;
  text-align    : center;
  margin        : 2rem;
  font-weight   : bold;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.29;
  letter-spacing: 1.67px;
  color         : #303031;
  padding-bottom: 20px;
  position      : relative;

  &:after {
    content   : "";
    width     : 80px;
    height    : 3px;
    position  : absolute;
    bottom    : 0;
    left      : 50%;
    transform : translateX(-50%);
    background: #e52628;
  }
}

.jobs-list {
  list-style    : none;
  display       : flex;
  flex-direction: column;
  width         : 100%;
}

.job__panel {
  display      : flex;
  align-items  : center;
  margin       : 1rem auto;
  width        : 100%;
  max-width    : 680px;
  transition   : 250ms;
  border-bottom: 1px solid #303031;
}

.job__title {
  display       : inline;
  font-family   : Poppins;
  font-size     : 19px;
  font-weight   : bold;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : 1.32;
  letter-spacing: 0.93px;
  text-align    : left;
  color         : #303031;
}

.job__button {
  width: 35%;
  margin-left  : auto;
  background   : white;
  font-weight  : bold;
  color        : #ccc;
  font-size    : 1.25rem;
  text-align    : right;
  border       : 0;
  border-radius: 0.5rem;
  border       : 1px solid white;
  cursor       : pointer;
  transition   : 250ms;

  &:hover {
    background: 0;
    color     : #303031;
  }
}

.job__details {
  max-width   : 680px;
  font-size   : 1.5rem;
  max-height  : 0px;
  overflow    : hidden;
  opacity     : 0;
  visibility  : hidden;
  transition  : 250ms;
  margin      : 0 auto;
  padding-left: 15px;

  * {
    font-family   : "Poppins";
    font-size     : 14px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.5;
    letter-spacing: 0.69px;
    text-align    : left;
    color         : #303031;
  }

  b {
    font-size     : 19px;
    font-weight   : bold;
    letter-spacing: 0.93px;
    margin-top    : 15px;
    display       : block;
  }
}

.job-button {
  width: 15rem;
  height: 3rem;
  cursor: pointer;
  text-align: center;
  margin-top      : 2rem;
  margin-bottom   : 1rem;
  background      : var(--color-desire);
  color           : white;
  transition      : 250ms;
  border          : 1px solid var(--color-desire);
  border-radius   : 4px;
  box-shadow      : 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #e52628;

  &:hover {
    color     : var(--color-desire);
    background: white;
  }
}

.center {
  text-align: center;
}

// Signup Page

.signup {
  padding-top  : 8%;
  margin-bottom: 5rem;

  .title {
    position      : relative;
    padding-bottom: 25px;
    margin-bottom : 25px;

    &:after {
      content   : "";
      position  : absolute;
      bottom    : 0;
      left      : 50%;
      transform : translateX(-50%);
      width     : 80px;
      height    : 3px;
      background: #e52628;
    }

    p {
      font-family   : "Poppins", sans-serif;
      font-weight   : bold;
      font-size     : 20px;
      letter-spacing: 0.05em;
      text-align    : center;
      color         : #aeaeae;
    }

    h5 {
      font-family   : "Poppins", sans-serif;
      font-weight   : bold;
      font-size     : 34px;
      letter-spacing: 0.05em;
      line-height   : 44px;
      text-align    : center;
      color         : #303031;
      margin-top    : 5px;
    }
  }

  .description {
    p {
      margin        : 0 auto;
      font-family   : "Poppins", sans-serif;
      font-weight   : normal;
      font-size     : 14px;
      letter-spacing: 0.05em;
      text-align    : center;
      color         : #303031;
      max-width     : 767px;
    }
  }

  .items {
    margin-top     : 60px;
    display        : flex;
    justify-content: space-between;
    align-items    : flex-start;

    .item {
      width          : 30%;
      display        : flex;
      align-items    : center;
      flex-direction : column;
      justify-content: center;

      .iconIletisim {
        width           : 95px;
        height          : 95px;
        background-color: #e52628;
        border-radius   : 50%;
        display         : flex;
        align-items     : center;
        justify-content : center;
        margin-bottom   : 25px;
      }

      .text {
        h5 {
          font-family   : "Poppins";
          font-size     : 14px;
          font-weight   : bold;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.5;
          letter-spacing: 0.69px;
          text-align    : center;
          color         : #303031;
          position      : relative;
          padding-bottom: 10px;
          margin-bottom : 10px;

          &:after {
            content   : "";
            position  : absolute;
            bottom    : 0;
            left      : 50%;
            transform : translateX(-50%);
            width     : 80px;
            height    : 3px;
            background: #e52628;
          }
        }

        p {
          font-family   : "Poppins";
          font-size     : 14px;
          font-weight   : normal;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.5;
          letter-spacing: 0.69px;
          text-align    : center;
          color         : #303031;
          max-width     : 200px;
        }

        ul {
          margin-top     : 15px;
          min-width      : 110px;
          list-style     : none;
          display        : flex;
          align-items    : center;
          justify-content: space-between;

          li {
            a {
              img {
                width : 20px;
                height: 20px;
              }
            }
          }
        }

        a {
          font-family   : "Poppins";
          font-size     : 14px;
          font-weight   : normal;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.5;
          letter-spacing: 0.69px;
          text-align    : center;
          color         : #303031;
          display       : block;
        }
      }
    }
  }
}

#iletisim {
  margin-top: 8%;
  background: #f8f8f8;

  .flexSection {
    width          : 100%;
    display        : flex;
    justify-content: space-between;

    .left {
      width     : 50%;
      background: url("../img/navisio_contact.jpeg") no-repeat center/cover;
    }

    .right {
      width  : 50%;
      padding: 7% 0;

      form {
        max-width: 611px;
        width    : 80%;
        margin   : 0 auto;

        .col-12 {
          margin-bottom: 15px;
        }

        input,
        textarea {
          margin        : 0;
          padding-left  : 10px;
          background    : transparent;
          font-size     : 14px;
          font-weight   : normal;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.5;
          letter-spacing: normal;
          text-align    : left;
          color         : #949494;
          border        : solid 1px rgba(#303031, 0.3);
        }

        textarea {
          padding-top: 5px;
        }

        button {
          cursor          : pointer;
          display         : flex;
          align-items     : center;
          justify-content : center;
          border          : none;
          margin          : 0 auto;
          margin-top      : 50px;
          width           : 295px;
          height          : 64px;
          border-radius   : 4px;
          box-shadow      : 0 3px 15px 0 rgba(0, 0, 0, 0.16);
          background-color: #e52628;
          font-size       : 17px;
          font-weight     : 600;
          font-stretch    : normal;
          font-style      : normal;
          line-height     : 1.53;
          letter-spacing  : 0.85px;
          text-align      : center;
          color           : #ffffff;
        }
      }
    }
  }
}

.signup__title {
  font-size    : 3rem;
  margin-top   : 2rem;
  margin-bottom: 2rem;
}

.signup-form {
  width : 50%;
  margin: auto;

  .job-button {
    width : 30%;
    height: 5rem;
  }
}

.signup__input-text {
  border      : 1.5px solid var(--color-desire);
  padding-left: 1rem;
}

.signup__input-check {
  width : 1.5rem;
  height: 1.5rem;
}

.signup__accept-terms {
  display    : flex;
  align-items: center;
  width      : 25rem;
  margin     : auto;
  font-size  : 1.25rem;
}

// Cookie

.cookie-policy {
  box-shadow : 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  position   : fixed;
  bottom     : 0;
  left       : 0;
  right      : 0;
  display    : flex;
  align-items: center;
  width      : 100%;
  font-size  : 1.25rem;
  height     : 8rem;
  z-index    : 2;
  background : white;
  color      : black;

  .cookie-policy__paragraph {
    width         : 75%;
    margin-left   : 1.25rem;
    font-weight   : normal;
    font-size     : 12px;
    letter-spacing: 0.01em;
    text-align    : left;
    color         : #303031;
    font-style    : normal;
  }

  .cookie-policy__button {
    border      : 0;
    background  : var(--color-desire);
    margin-left : auto;
    margin-right: 1.25rem;
    padding     : 1rem;
    color       : #fff;
    cursor      : pointer;

    i {
      margin-right: 10px;
    }
  }
}

// Animations

@keyframes moveInLeft {
  0% {
    opacity  : 0;
    transform: translate(-100px);
  }

  100% {
    opacity  : 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity  : 0;
    transform: translate(100px);
  }

  100% {
    opacity  : 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity  : 0;
    transform: translateY(20px);
  }

  100% {
    opacity  : 1;
    transform: translateY(0);
  }
}

// Modal
.overlay {
  position  : fixed;
  top       : 0;
  right     : 0;
  bottom    : 0;
  left      : 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms;
  visibility: hidden; //
  opacity   : 0; //
  z-index   : 999;
  width     : 100vw;
  height    : 100vh;

  &:target {
    visibility: visible;
    opacity   : 1;
  }
}

.close {
  font-size     : 2.5rem;
  text-transform: uppercase;
  margin-top    : -10px;
  margin-left   : calc(100% - 20px);
  cursor        : pointer;
}

.popup {
  margin          : 5% auto;
  padding         : 20px;
  background      : var(--color-white);
  border-radius   : 5px;
  width           : 90%;
  max-width       : 812px;
  position        : relative;
  transition      : all 0.3s ease-in-out;
  transition-delay: -0.2s;

  .popupArea {
    max-width: 611px;
    margin   : 0 auto;

    h1 {
      font-size     : 34px;
      font-weight   : bold;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : 1.29;
      letter-spacing: 1.67px;
      text-align    : center;
      color         : #303031;
      position      : relative;
      padding-bottom: 15px;
      margin-bottom : 20px;

      &:before {
        content   : "";
        position  : absolute;
        bottom    : 0;
        left      : 50%;
        height    : 3px;
        width     : 80px;
        background: #e52628;
        transform : translateX(-50%);
      }
    }

    input[type="file"] {
      visibility: hidden;
      position  : absolute;
    }

    .fileUpload {
      margin-bottom   : 20px;
      text-align      : center;
      display         : flex;
      align-items     : center;
      cursor          : pointer;
      justify-content : center;
      height          : 75px;
      border-radius   : 4px;
      border          : dashed 0.5px #303031;
      background-color: #ffffff;
    }
  }
}

.hidden {
  display: none;
}

.col-12 {
  width          : 100%;
  display        : flex;
  justify-content: space-between;
  margin-bottom  : 12px;

  .col-6 {
    width: 48%;
  }

  input,
  textarea {
    width         : 100%;
    border-radius : 4px;
    border        : solid 1px rgba(#303031, 0.3);
    font-family   : "Poppins";
    font-size     : 14px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.5;
    letter-spacing: normal;
    text-align    : left;
    color         : #949494;
  }

  input {
    height: 40px;
  }

  textarea {
    padding-top: 5px;
    height     : 165px;
  }
}

.apply-form {
  .apply-input {
    padding-left: 1rem;
  }

  label {
    font-size: 1.2rem;
  }

  .job-button {
    width  : 295px;
    height : 5rem;
    margin : 0 auto;
    display: block;
  }

  >p {
    font-family   : "Poppins";
    font-size     : 14px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.5;
    letter-spacing: normal;
    text-align    : left;
    color         : #949494;
  }
}

// Password Recovery
*:focus {
  outline: none !important;
}

.passwordRecoveryPage {
  display        : flex;
  justify-content: space-between;
  width          : 100vw;
  height         : 100vh;
  align-items    : center;

  .backImg {
    height    : 100%;
    object-fit: contain;
  }
}

.mainArea {
  max-width : 512px;
  padding   : 2% 0;
  box-sizing: border-box;

  .logo {
    text-align: center;

    img {
      width    : 100%;
      max-width: 431px;
    }

    p {
      margin-top : 6%;
      font-family: "Poppins";
      font-weight: 600;

      font-size     : 18px;
      text-align    : center;
      letter-spacing: 0.02em;
    }
  }

  .hide {
    opacity   : 0;
    margin-top: -94px;
    z-index   : -1;
  }

  .errorMessage {
    background: #f85c50;
    color     : #fff;
    font-size : 13px;
    text-align: center;
    padding   : 7px 10px;
  }

  .errorLabel {
    display: none;
  }

  .error .errorLabel {
    display      : flex;
    align-items  : center;
    position     : absolute;
    right        : 104%;
    color        : #fff;
    font-size    : 12px;
    padding      : 10px;
    padding-left : 15px;
    margin       : 0;
    top          : 57px;
    white-space  : nowrap;
    background   : #2babe2;
    border-radius: 10px;
    margin-top   : 5px;

    svg {
      margin-right: 10px;
    }
  }

  .error .errorLabel::before {
    content     : "";
    position    : absolute;
    right       : -8px;
    top         : 5px;
    border-style: solid;
    border-width: 10px 0px 10px 10px;
    border-color: transparent transparent transparent #2babe2;
  }

  .form {
    max-width : 448px;
    margin    : 0 auto;
    margin-top: 2%;
  }

  .inputGroup {
    position      : relative;
    z-index       : 1;
    margin-bottom : 9px;
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    transition    : opacity 0.3s ease-in-out, margin 0.3s ease-in-out;

    label {
      margin-bottom: 3px;
      font-family  : "Poppins";
      font-weight  : 600;

      font-size     : 16px;
      letter-spacing: 0.02em;
      text-align    : left;
      color         : #2babe2;
      margin-left   : 25px;
    }

    small {
      font-family : "Poppins";
      font-weight : 100;
      font-size   : 12px;
      padding-left: 25px;
    }

    .textInput {
      width         : 100%;
      height        : 46px;
      background    : #f8f8f8;
      border        : 1px solid #70707017;
      font-family   : "Poppins";
      font-weight   : normal;
      font-size     : 16px;
      letter-spacing: 0.02em;
      text-align    : left;
      color         : #333;
      border-radius : 20px;
      padding-left  : 25px;
    }
  }

  button.submitButton {
    position      : relative;
    float         : right;
    margin-top    : 15px;
    width         : 153px;
    height        : 46px;
    background    : #298ac8;
    border        : 0.1px solid #707070;
    font-family   : "Poppins";
    font-weight   : 100;
    font-size     : 18px;
    letter-spacing: 0.02em;
    text-align    : center;
    color         : #fff;
    text-transform: uppercase;
    border-radius : 20px;
    cursor        : pointer;
    transition    : all 0.3s ease-in-out;
  }
}

.customAlert {
  display      : flex;
  align-items  : center;
  height       : 80px;
  border-radius: 10px;
  border       : 1px solid rgba(#999999, 0.4);
  overflow     : hidden;

  .left {
    height         : 100%;
    padding        : 10px;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .right {
    padding: 5px 15px;

    .alertTitle {
      font-family: "Poppins";
      font-weight: 600;

      font-size: 14px;
    }

    .alertText {
      font-family: "Poppins";
      font-weight: 600;

      font-size: 14px;
    }
  }
}

.customAlert.errorAlert {
  .left {
    background: #e52628;
  }

  .right {
    .alertTitle {
      color: #e52628;
    }
  }
}

.customAlert.successAlert {
  .left {
    background: #27ae60;
  }

  .right {
    .alertTitle {
      color: #27ae60;
    }
  }
}

@media only screen and (min-width: 360px) {
  .references {
    font-size: 12px !important;

  h1 {
    font-size: 16px !important;
    margin: 0rem 0rem 3rem 0rem;

  }
}

}


@media only screen and (min-width: 992px) {
  .references {
    padding-top: 4vh;
    // min-height: 100vh;
    padding-bottom: 5%;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    font-size: 18px !important;;
  
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 2rem 0rem 0rem 0rem;
    }
  
    &-card {
      width: 49% !important;
      padding: 1rem 1rem 1rem 1rem;
      background: #fff;
  
      &--header {
        font-size: 18px;
        
      }
  
      &--body {
        font-size: 14px;

        & p{
          margin: 1rem 0rem 1rem 0rem;
        }
  
  
      }
  
      &--image {
        width: 100%;
      }
    }
  }
  .passwordRecoveryPage {
    .mainArea {
      max-width: 75%;

      .form {
        max-width: 95%;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {


  .passwordRecoveryPage {
    justify-content: center;

    .backImg {
      display: none;
    }
  }

  .mainArea .error .errorLabel {
    position: relative;
    right   : initial;
    left    : 25px;
    top     : 5px;

    &:before {
      right       : initial;
      left        : 5%;
      top         : -5px;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #2babe2 transparent;
    }
  }
}

@media only screen and (min-width: 1360px){

  .mainArea .logo img {
    width    : 100%;
    max-width: 260px;
  }

  .mainArea .logo p {
    font-weight: 100;
    font-size  : 14px;
  }
}
