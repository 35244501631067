:root {
  // COLORS
  --color-desire: #e52628;
  --color-fusion-red: rgba(252, 92, 101, 1);
  --color-grey-dark: rgb(102, 102, 102, 1);
  --color-twinkle-blue: rgba(209, 216, 224, 1);
  --color-blue-grey: rgba(119, 140, 163, 1);
  --color-innuendo: rgb(191, 203, 219);
  --color-blue-horizon: rgba(75, 101, 132, 1);
  --color-azure: rgba(240, 255, 255, 1);
  --color-white: rgba(255, 255, 255, 1);
}

@mixin flex {
  display: flex;
  justify-content: center;
}

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1 rem = 10px, 10px/16px = 62.5%
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: var(--color-grey-dark);
}

article,
aside,
footer,
header,
hgroup,
main,
nav,
section {
  display: flex;
}

a {
  text-decoration: none;
}
