.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.row {
    width: 120rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.header {
    justify-content: center;
    align-items: center;
    height: 12vh;
}
.main--back {
    display: flex;
    align-items: flex-end;
    // background-image: linear-gradient(
    //         to right,
    //         rgba(255, 0, 0, 0.1),
    //         rgba(252, 92, 101, 0.3)
    //     ),
    //     url("../img/infinity.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100vh;
}

.main {
    color: var(--color-azure);
    display: flex;
    align-items: center;
    height: 88vh;
    @include flex;
}

.sec-prices {
}

.sec-service--back {
    background: var(--color-twinkle-blue);
    display: flex;
}

.sec-service {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sec-order {
    @include flex;
}

.sec--pageroot__back {
    height: 6rem;
    background: var(--color-desire);
    padding: 3rem 0 0 0;
    color: var(--color-azure);
    font-size: 1.6rem;
}

.footer--back {
    background: linear-gradient(
        -15deg,
        var(--color-blue-grey),
        var(--color-desire)
    );
}

.footer {
    color: var(--color-azure);
    @include flex;
}

@media only screen and (max-device-width: 1200px) {
    .main-back {
        background-attachment: scroll;
    }
}
