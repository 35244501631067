body {
  font-family: "Poppins", sans-serif;
}

.header--left {
  padding: 0 !important;
}

.header--wrapper {
  z-index   : 9;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
}

header.header {
  position: relative;
}

.header--right {
  display: none;
}

.mobileMenu {
  opacity    : 0;
  visibility : hidden;
  position   : fixed;
  width      : 75%;
  height     : 100%;
  left       : 0;
  top        : -100%;
  z-index    : 4;
  padding    : 7%;
  padding-top: 110px;
  transition : all 0.3s ease-in-out;

  &::before {
    content   : "";
    position  : absolute;
    left      : 0%;
    top       : 0%;
    width     : 0;
    z-index   : -1;
    height    : 100%;
    background: #fff;
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.16);
  }

  ul {
    opacity         : 0;
    list-style      : none;
    transition      : all 0.3s ease-in-out;
    transition-delay: -0.3s;

    li {
      padding   : 10px 0;
      border-top: 1px solid #ddd;

      .mobileBadge {
        float: right;
      }

      a {
        font-weight: 600;
        font-size  : 15px;
        text-align : left;
        color      : #303031;
        font-family: "Montserrat", sans-serif;
      }

      ul {
        opacity     : 0;
        visibility  : hidden;
        max-height  : 0;
        padding-left: 10px;
        transition  : all 0.3s ease-in-out;

        li {
          opacity   : 0;
          transition: all 0.3s ease-in-out;
          padding   : 7px;
          border    : none;

          a {
            font-size: 12px;
          }
        }
      }

      &:hover {
        >a {
          color: #e52628;
        }

        ul {
          margin-top      : 10px;
          opacity         : 1;
          visibility      : visible;
          max-height      : 100vw;
          transition-delay: 0.2s;

          li {
            opacity         : 1;
            transition-delay: 0.2s;
          }
        }
      }
    }
  }

  .languages {
    border-top : 1px solid #ddd;
    position   : relative;
    display    : flex;
    align-items: center;
    padding-top: 10px;

    &:hover {
      >ul {
        opacity   : 1;
        visibility: visible;
        left      : 70px;
      }
    }

    .active {
      cursor     : pointer;
      display    : flex;
      align-items: center;

      &:after {
        padding-left: 10px;
        content     : url("../img/line.svg");
      }

      p {
        font-weight: 600;
        font-size  : 12px;
        text-align : left;
        color      : #303031;
        margin-left: 5px;
      }
    }

    >ul {
      opacity    : 0;
      visibility : hidden;
      list-style : none;
      position   : absolute;
      left       : 40px;
      display    : flex;
      align-items: center;
      margin-left: 10px;
      transition : all 0.3s ease-in-out;

      li {
        border      : none;
        margin-right: 5px;
        opacity     : 0.45;

        button {
          cursor     : pointer;
          border     : none;
          box-shadow : none;
          background : transparent;
          font-weight: 600;
          font-size  : 12px;
          text-align : left;
          color      : #303031;
          font-family: Poppins;
        }
      }

      .lineLanguage {
        border-right: 3px solid #303031;
        height      : 20px;
      }
    }
  }
}

.megaMenu {
  visibility : hidden;
  opacity    : 0;
  transition : all 0.3s ease-in-out;
  position   : absolute;
  top        : 91px;
  left       : 0;
  width      : 100%;
  padding    : 1% 5%;
  padding-top: 2%;
  background : #fff;
  box-shadow : 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  overflow   : hidden;

  li {
    text-decoration: none;
    display        : block;
    //padding      : 3rem 0 0 0;
    width          : 10rem;
    display        : flex;
    align-items    : center;
    justify-content: center;
    text-align     : center;
    font-size      : 1.6rem;
    cursor         : pointer;

    &:hover {
      color: var(--color-desire);

      >a {
        color: var(--color-desire) !important;
      }
    }

    a {
      width          : 100%;
      height         : 100%;
      display        : flex;
      align-items    : center;
      justify-content: center;
    }
  }



  &::before {
    content : url("../img/megaMenuLogo.svg");
    position: absolute;
    left    : 0;
    bottom  : -13px;
  }

  .flexSection {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;

    .left {
      width        : 25%;
      padding-right: 5%;
      position     : relative;

      &:after {
        content         : "";
        position        : absolute;
        right           : 0;
        top             : 50%;
        width           : 1px;
        opacity         : 0.93;
        box-shadow      : 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #303031;
        height          : 200px;
        transform       : translateY(-50%);
      }

      ul {
        display   : block;
        list-style: none;

        li {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 24px;
          }

          a {
            font-size      : 15px;
            font-weight    : 600;
            font-stretch   : normal;
            font-style     : normal;
            line-height    : 1.53;
            letter-spacing : normal;
            text-align     : left;
            transition     : all 0.3s ease-in-out;
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            color          : #303031;

            &::before {
              content   : url("../img/icon.svg");
              opacity   : 0;
              transition: all 0.3s ease-in-out;
              margin-top: 5px;
            }

            &:hover {
              color: #e52628;

              &::before {
                opacity     : 1;
                margin-right: 5px;
              }
            }
          }
        }

        li.active {
          a {
            color: #e52628;

            &::before {
              opacity     : 1;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .right {
      padding-left   : 2%;
      width          : 70%;
      display        : flex;
      justify-content: space-between;
      align-items    : center;

      img {
        width: 100%;
      }

      .megaLogo {
        width              : 100%;
        text-align         : left;
        background-image   : url("../img/menuImg.png");
        background-repeat  : no-repeat;
        background-position: right top;
        background-size    : 36%;

        img {
          max-width: 180px;
          height   : 60px;
        }

        p {
          margin-top    : 20px;
          max-width     : 33rem;
          margin-bottom : 10px;
          font-size     : 13px;
          font-weight   : 200;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.5;
          letter-spacing: normal;
          text-align    : left;
          color         : #aaaaaa;
        }
      }

      .megaReview {
        img {
          max-width: 394px;
        }
      }
    }
  }
}

.header {
  height         : 91px;
  justify-content: space-between;

  .header--menu {
    margin : initial;
    display: flex;

    >ul {
      align-items    : center;
      justify-content: space-between;

      li {
        width : auto;
        height: 100%;

        &:not(:last-child) {
          margin-right: 45px;
        }

        a {
          height     : 100%;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size  : 15px;
          text-align : left;
          color      : #303031;
        }

        &:hover {
          .megaMenu {
            visibility: visible;
            opacity   : 1;
          }
        }
      }
    }

    .languages {
      position   : relative;
      margin-left: 35px;
      display    : flex;
      align-items: center;

      &:hover {
        >ul {
          opacity   : 1;
          visibility: visible;
          right     : 0;
        }
      }

      .active {
        cursor     : pointer;
        display    : flex;
        align-items: center;

        &:after {
          padding-left: 10px;
          content     : url("../img/line.svg");
        }

        p {
          font-weight: 600;
          font-size  : 12px;
          text-align : left;
          color      : #303031;
          margin-left: 5px;
        }
      }

      >ul {
        opacity    : 0;
        visibility : hidden;
        list-style : none;
        position   : absolute;
        right      : 20%;
        transform  : translateX(130%);
        display    : flex;
        align-items: center;
        margin-left: 10px;
        transition : all 0.3s ease-in-out;

        li {
          margin-right: 5px;
          opacity     : 0.45;

          button {
            cursor     : pointer;
            border     : none;
            box-shadow : none;
            background : transparent;
            font-weight: 600;
            font-size  : 12px;
            text-align : left;
            color      : #303031;
            font-family: Poppins;
          }
        }

        .lineLanguage {
          border-right: 3px solid #303031;
          height      : 20px;
        }
      }
    }
  }
}

.main--back {
  min-height: 600px;
  position  : relative;
}

.video-overlay {
  background    : rgba(#e52628, 0.93);
  box-shadow    : 0px 3px 15px rgba(0, 0, 0, 0.16);
  mix-blend-mode: multiply;
}

.sliderTexts {
  width    : 100%;
  position : absolute;
  top      : 50%;
  transform: translateY(-50%);
  overflow : hidden;

  h5 {
    margin        : 0 auto;
    font-family   : "Poppins", sans-serif;
    font-weight   : 600;
    font-size     : 20px;
    line-height   : 1.24em;
    max-width     : 632px;
    letter-spacing: 0.05em;
    text-align    : center;
    color         : #fff;
    margin-bottom : 20px;
    text-shadow   : 0px 3px 15px rgba(0, 0, 0, 0.16);
  }

  h2 {
    position      : relative;
    font-family   : "Poppins", sans-serif;
    font-weight   : bold;
    max-width     : 890px;
    font-size     : 42px;
    letter-spacing: 0.05em;
    line-height   : 1.24em;
    letter-spacing: 0.05em;
    text-align    : center;
    margin        : 0 auto;
    margin-bottom : 20px;
    color         : #fff;
    text-shadow   : 0px 3px 15px rgba(0, 0, 0, 0.16);
    text-transform: lowercase;
  }

  .slick-prev {
    left: 5%;
  }

  .slick-next {
    right: 5%;
  }

  .mainBtn {
    margin         : 0 auto;
    margin-top     : 10px;
    width          : 295px;
    height         : 64px;
    border-radius  : 4px;
    background     : #fff;
    box-shadow     : 0px 3px 15px rgba(0, 0, 0, 0.16);
    font-family    : "Poppins", sans-serif;
    font-weight    : 600;
    font-size      : 17px;
    letter-spacing : 0.05em;
    text-align     : center;
    color          : #e52628;
    display        : flex;
    align-items    : center;
    justify-content: center;
    transition     : all 0.3s ease-in-out;

    &:hover {
      background: #e52628;
      color     : #fff;
    }
  }
}

.clearfix:after {
  visibility: hidden;
  display   : block;
  font-size : 0;
  content   : " ";
  clear     : both;
  height    : 0;
}

.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.about {
  padding-top   : 6vh;
  padding-bottom: 5%;
  background    : #f8f8f8;

  p {
    font-family: "Poppins", sans-serif;
    font-size  : 17px;
    text-align : left;
    color      : #333;
    margin-top : 10px;
  }

  ul {
    margin-top  : 10px;
    display     : block;
    width       : 100%;
    padding-left: 20px;

    li {
      p {
        margin: 0;
      }
    }
  }
}

.project {
  padding-top   : 6vh;
  padding-bottom: 5%;
  background    : #f8f8f8;

  .block {
    display      : flex;
    margin-top   : 20px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    justify-content:space-between;
    border-bottom: 1px solid #ccc;

    .project-image {
      width: 15%;

      img {
        width     : 200px;
        border    : 1px solid #f1f1f1;
        padding   : 20px;
        box-shadow: 1px 1px 11px 1px rgba(#d7d7d7, 0.7);
      }

    }

    .project-text {
      width: 85%;

      p {
        margin-left: 40px;
        font-family: "Poppins", sans-serif;
        font-size  : 17px;
        text-align : left;
        color      : #333;
      }

    }
  }
}

p,
h4 {
  display: block;
}

.references {
  padding-top: 4vh;
  // min-height: 100vh;
  padding-bottom: 5%;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  font-size: 14px;


  h1 {
    width: 70%;
    margin: 0rem 0rem 3rem 0rem;

  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0rem 0rem 0rem;
    justify-content:space-between;
  }

  &-card {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    margin: 0rem 0rem 3rem 0rem;
    border-radius: 6px;
    box-shadow: 0 10px 20px -7px rgba(0,0,0,0.25);

    &--image_background {
      width: 100%;
      height: 250px;
    }

    &--header {
      font-size: 18px;
      
    }

    &--body {
      font-size: 14px;
      

      & p{
        margin: 1rem 0rem 1rem 0rem;
      }

    }

    &--image {
      width: 100%;
    }
  }
}

.services {
  background    : #f8f8f8;
  padding-top   : 6%;
  position      : relative;
  overflow-x    : hidden;
  padding-bottom: 7%;

  .item {
    width          : 100%;
    display        : flex;
    align-items    : flex-start;
    justify-content: space-between;

    h3.shadowText {
      font-weight   : bold;
      font-size     : 30em;
      letter-spacing: 0.05em;
      text-align    : left;
      color         : #fff;
      position      : absolute;
      line-height   : 0.8em;
      z-index       : 1;
      left          : 0;
    }

    .left {
      z-index: 2;
      width  : 64%;
    }

    .right {
      z-index : 2;
      width   : 33%;
      position: relative;

      &::before {
        content : "";
        position: absolute;
        right   : -5%;
        top     : 2%;
        width   : 84%;
        height  : 86%;
        z-index : -1;
        border  : 9px solid #e52628;
      }

      img {
        width     : 100%;
        margin-top: 10%;
      }
    }

    .title {
      position      : relative;
      padding-bottom: 25px;
      margin-bottom : 25px;

      &:after {
        content   : "";
        position  : absolute;
        bottom    : 0;
        left      : 0;
        width     : 80px;
        height    : 3px;
        background: #e52628;
      }

      p {
        font-family   : "Poppins", sans-serif;
        font-weight   : bold;
        font-size     : 20px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #aeaeae;
      }

      h5 {
        font-family   : "Poppins", sans-serif;
        font-weight   : bold;
        font-size     : 34px;
        letter-spacing: 0.05em;
        line-height   : 44px;
        text-align    : left;
        color         : #303031;
        margin-top    : 5px;
      }
    }

    .description {
      p {
        font-family   : "Poppins", sans-serif;
        font-weight   : normal;
        font-size     : 14px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #303031;
        max-width     : 767px;
      }
    }

    .boxes {
      padding        : 0 20px;
      margin-top     : 50px;
      display        : flex;
      flex-wrap      : wrap;
      justify-content: space-between;

      .box {
        .img {
          width: 90px;

          img {
            width: 100%;
          }
        }

        display      : flex;
        align-items  : flex-start;
        width        : 45%;
        margin-bottom: 50px;

        .body {
          margin-left: 20px;

          .title {
            padding-bottom: 9px;
            margin-bottom : 9px;

            h5 {
              padding       : 0;
              margin        : 0;
              font-weight   : bold;
              font-size     : 19px;
              letter-spacing: 0.05em;
              line-height   : 25px;
              text-align    : left;
              color         : #303031;
            }
          }
        }
      }
    }

    .btnAll {
      margin-top     : 10px;
      width          : 295px;
      height         : 64px;
      border-radius  : 4px;
      background     : #e52628;
      box-shadow     : 0px 3px 15px rgba(0, 0, 0, 0.16);
      font-family    : "Poppins", sans-serif;
      font-weight    : 600;
      font-size      : 17px;
      letter-spacing : 0.05em;
      text-align     : center;
      color          : #fff;
      display        : flex;
      align-items    : center;
      justify-content: center;
      transition     : all 0.3s ease-in-out;

      &:hover {
        background: #fff;
        color     : #e52628;
      }
    }
  }
}

.result {
  padding    : 10px;
  font-family: "Poppins", sans-serif;
  font-size  : 13px;
  text-align : left;
  color      : #333;
  margin-top : 10px;
  width      : 100%;
}

.successResult {
  background-color: green;
  color           : #fff;
}

.errorResult {
  background-color: var(--color-desire);
  color           : #fff;
}

.servicesPage {
  padding-top   : 4vh;
  min-height    : 40vh;
  padding-bottom: 5%;
  background    : #f8f8f8;

  .row {
    padding: 0 10px;

    h3 {
      font-family: "Poppins", sans-serif;
      font-size  : 17px;
      text-align : left;
      color      : #333;
      margin-top : 30px;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-size  : 17px;
      text-align : left;
      color      : #333;
      margin-top : 10px;
      width      : 100%;
    }
  }
}

.services.second {
  background: #fff;

  .item {
    h3.shadowText {
      font-weight   : bold;
      font-size     : 385px;
      letter-spacing: 0.05em;
      text-align    : right;
      color         : #f9f9f9;
    }

    .left {
      order: 2;
      width: 40%;

      .boxes {
        padding: 0;

        .box {
          width        : 100%;
          margin-bottom: 30px;

          .body {
            margin: 0;

            .title {
              margin-bottom: 3px;

              h5 {
                position  : relative;
                transition: all 0.3s ease-in-out;

                &::before {
                  content   : url("../img/icon.svg");
                  opacity   : 0;
                  transition: all 0.3s ease-in-out;
                  margin-top: 5px;
                  position  : absolute;
                  left      : 0;
                  top       : 50%;
                  transform : translateY(-54%);
                }

                &:hover {
                  color       : #e52628;
                  padding-left: 14px;

                  &::before {
                    opacity: 1;
                  }
                }
              }
            }

            .description {
              p {
                max-width: 444px;
              }
            }
          }
        }
      }
    }

    .right {
      order: 1;
      width: 43%;

      &::before {
        content : "";
        position: absolute;
        left    : -1%;
        top     : 2%;
        width   : 75%;
        height  : 86%;
        z-index : -1;
        border  : 9px solid #e52628;
      }
    }
  }
}

.widgetBox {
  background: #f8f8f8;
  max-width : 1920px;
  margin    : 0 auto;

  .flexSection {
    width          : 100%;
    display        : flex;
    justify-content: space-between;
    align-items    : stretch;

    .left {
      width        : 50%;
      padding      : 5%;
      padding-left : 9%;
      padding-right: 4%;

      .title {
        position      : relative;
        padding-bottom: 25px;
        margin-bottom : 25px;

        &:after {
          content   : "";
          position  : absolute;
          bottom    : 0;
          left      : 0;
          width     : 80px;
          height    : 3px;
          background: #e52628;
        }

        p {
          font-family   : "Poppins", sans-serif;
          font-weight   : bold;
          max-width     : 650px;
          font-size     : 20px;
          letter-spacing: 0.05em;
          text-align    : left;
          color         : #aeaeae;
        }

        h5 {
          font-family   : "Poppins", sans-serif;
          font-weight   : bold;
          font-size     : 25px;
          max-width     : 600px;
          letter-spacing: 0.05em;
          line-height   : 1.2em;
          text-align    : left;
          color         : #303031;
          margin-top    : 5px;
        }
      }

      .description {
        p {
          font-family   : "Poppins", sans-serif;
          font-weight   : normal;
          font-size     : 14px;
          letter-spacing: 0.05em;
          text-align    : left;
          color         : #303031;
          max-width     : 650px;
        }
      }

      a {
        margin-top     : 40px;
        width          : 295px;
        height         : 64px;
        border-radius  : 4px;
        background     : #e52628;
        box-shadow     : 0px 3px 15px rgba(0, 0, 0, 0.16);
        font-family    : "Poppins", sans-serif;
        font-weight    : 600;
        font-size      : 17px;
        letter-spacing : 0.05em;
        text-align     : center;
        color          : #fff;
        display        : flex;
        align-items    : center;
        justify-content: center;
        transition     : all 0.3s ease-in-out;

        &:hover {
          background: #fff;
          color     : #e52628;
        }
      }
    }

    .right {
      width     : 50%;
      background: url("../img/pc.png") no-repeat center/cover;
    }

    .insanKaynaklari {
      background: url("../img/insanKaynaklari.png") no-repeat center/cover;
    }
  }
}

.plain {
  margin-top    : 50px;
  padding-bottom: 5%;
  padding-top   : 5%;

  .title {
    position      : relative;
    padding-bottom: 25px;
    margin-bottom : 25px;
    width         : 100%;

    &:after {
      content   : "";
      position  : absolute;
      bottom    : 0;
      left      : 50%;
      transform : translateX(-50%);
      width     : 80px;
      height    : 3px;
      background: #e52628;
    }

    p {
      font-family   : "Poppins", sans-serif;
      font-weight   : bold;
      font-size     : 20px;
      letter-spacing: 0.05em;
      text-align    : center;
      color         : #aeaeae;
    }

    h5 {
      font-family   : "Poppins", sans-serif;
      font-weight   : bold;
      font-size     : 34px;
      max-width     : 750px;
      margin        : 0 auto;
      letter-spacing: 0.05em;
      line-height   : 44px;
      text-align    : center;
      color         : #303031;
      margin-top    : 5px;
    }
  }

  .items {
    width: 100%;

    .slick-slide {
      transform: scale(0.9);
    }

    .slick-next {
      &::before {
        content: url("../img/right.svg");
      }
    }

    .slick-prev {
      &::before {
        content: url("../img/left.svg");
      }
    }

    .slick-dots {
      position  : relative;
      margin-top: 3%;
    }

    .item {
      .detail {
        padding      : 20px;
        border-radius: 4px;
        background   : #fff;
        box-shadow   : 0px 3px 15px rgba(0, 0, 0, 0.16);
        min-height   : 300px;

        .top {
          display        : flex;
          align-items    : center;
          justify-content: flex-start;

          h5 {
            font-weight   : bold;
            font-size     : 18px;
            letter-spacing: 0.05em;
            text-align    : left;
            line-height   : 1.3em;
            color         : #303031;
            margin-left   : 14px;
          }
        }

        .body {
          margin-top: 20px;

          p {
            font-weight   : normal;
            font-size     : 14px;
            letter-spacing: 0.05em;
            text-align    : left;
            color         : #303031;
          }
        }
      }

      .footer {
        margin-top: 10px;
        position  : relative;

        &:after {
          content   : "";
          position  : absolute;
          right     : 0;
          width     : 70%;
          height    : 3px;
          background: #e52628;
        }

        p {
          background    : #fff;
          width         : 100%;
          font-weight   : bold;
          font-size     : 34px;
          letter-spacing: 0.05em;
          line-height   : 44px;
          text-align    : left;
          color         : #aeaeae;
        }
      }
    }
  }
}

.danismanlik {
  .item {
    align-items: center;

    .left {
      order: 2;
      width: 50%;

      .description {
        p {
          max-width: 542px;
        }
      }

      a {
        margin-top: 50px;
      }
    }

    .right {
      order: 1;
      width: 40%;

      &::before {
        left  : -4%;
        top   : 5%;
        width : 85%;
        height: 85%;
      }
    }
  }
}

// #referance {
//   padding   : 2% 0;
//   background: #fff;
//   box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);

//   .items {
//     width: 100%;

//     .slick-slide {
//       transform: scale(0.8);
//     }

//     .slick-next {
//       &::before {
//         content: url("../img/right.svg");
//       }
//     }

//     .slick-prev {
//       &::before {
//         content: url("../img/left.svg");
//       }
//     }

//     .item {
//       background: #fff;
//       box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);

//       a {
//         width          : 100%;
//         height         : 100%;
//         display        : flex;
//         align-items    : center;
//         justify-content: center;
//       }
//     }
//   }
// }

footer {
  padding      : 4% 0;
  border-bottom: 42px solid #e52628;

  .columns {
    width          : 100%;
    display        : flex;
    justify-content: space-between;

    .column {
      &:nth-child(1) {
        width        : 27%;
        padding-right: 4%;
        border-right : 1px solid #303031;
      }

      &:nth-child(2) {
        width        : 18%;
        padding-left : 2%;
        padding-right: 4%;
        border-right : 1px solid #303031;
      }

      &:nth-child(3) {
        width        : 25%;
        padding-left : 2%;
        padding-right: 4%;
        border-right : 1px solid #303031;
      }

      &:nth-child(4) {
        padding-left: 2%;
        width       : 24%;
      }

      address {
        max-width     : 157px;
        font-weight   : normal;
        font-size     : 14px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #303031;
        font-style    : normal;
      }

      p {
        max-width     : 298px;
        font-weight   : normal;
        font-size     : 14px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #303031;
      }

      h5 {
        font-weight   : bold;
        font-size     : 20px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #303031;
        position      : relative;
        padding-bottom: 5px;
        margin-bottom : 10px;

        &:after {
          content   : "";
          position  : absolute;
          bottom    : 0;
          left      : 0;
          width     : 80px;
          height    : 3px;
          background: #e52628;
        }
      }

      .box {
        margin-top: 30px;

        a {
          font-family   : Poppins;
          font-weight   : normal;
          font-size     : 14px;
          letter-spacing: 0.05em;
          text-align    : left;
          color         : #e52628;
        }
      }

      a.showMap {
        display       : inline-block;
        margin-top    : 30px;
        font-weight   : normal;
        font-size     : 14px;
        letter-spacing: 0.05em;
        text-align    : left;
        color         : #303031;
      }

      span {
        font-weight: normal;
        font-size  : 12px;
        text-align : left;
        color      : #303031;
      }

      ul {
        list-style: none;

        li {
          &:not(:last-child) {
            margin-bottom: 14px;
          }

          a {
            font-weight: normal;
            font-size  : 14px;
            text-align : left;
            color      : #303031;
          }
        }
      }

      .socials {
        width          : 50%;
        margin-top     : 25px;
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        .social {
          a {
            img {
              width     : 30px;
              height    : 30px;
              object-fit: contain;
            }
          }
        }
      }

      .emailBox {
        margin-top   : 25px;
        margin-bottom: 25px;
        width        : 100%;
        display      : flex;
        height       : 49px;
        border-radius: 4px;
        background   : #fff;
        box-shadow   : 0px 3px 15px rgba(0, 0, 0, 0.16);

        input {
          width      : 100%;
          height     : 100%;
          padding    : 0 10px;
          margin     : 0;
          border     : none;
          background : transparent;
          font-family: "Poppins", sans-serif;
        }

        .emailBoxIcon {
          cursor       : pointer;
          width        : 49px;
          height       : 49px;
          display      : flex;
          align-items  : center;
          border-radius: 4px;
          background   : #e52628;
        }
      }
    }
  }
}

.hiddenDesktop {
  display: none;
}

.swal2-popup {
  width     : 42em;
  background: #e52628 !important;

  .swal2-title {
    color      : #fff !important;
    max-width  : 80%;
    line-height: 1.2em;
    font-size  : 17px;
  }

  #swal2-content {
    color    : #fff;
    max-width: 80%;
    margin   : 0 auto;
    font-size: 14px;
  }

  .swal2-icon {
    border-color: #fff !important;
  }

  .swal2-icon [class^=swal2-x-mark-line] {
    background-color: #fff !important;
  }

  .swal2-icon.swal2-success .swal2-success-ring {
    border-color: #fff !important;
  }

  .swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #fff !important;
  }

  button {
    background: #fff !important;
    color     : #333 !important;
    font-size : 14px !important;
    border    : none !important;
    outline   : none !important;
  }
}

@media (max-width: 1260px) {

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .row {
    width: 88%;
  }

  .header .header--menu>ul li:not(:last-child) {
    margin-right: 16px;
  }

  .plain .items .item .detail .top {
    min-height: 66px;
  }

  .header .header--menu>ul li a {
    font-size: 13px;
  }

  .plain .items .item .detail .top h5 {
    font-size  : 18px;
    line-height: 1.4em;
  }

  .services .item .title h5 {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  body {
    overflow-x: hidden;
  }

  .project {
    padding-top: 5vh;

    .block {
      flex-direction: column;

      img {
        border    : none;
        box-shadow: none;
      }

      p {
        margin-left: 0;
      }
    }

  }

  .ml-form-embedContainer {
    display: none !important;
  }

  .signup {
    padding-top: 9vh;
  }

  .jobs {
    padding-top: 7%;
  }

  .jobs-title {
    font-size: 20px;
  }

  .col-12 {
    flex-direction: column;
    margin-bottom : 0 !important;

    .col-6 {
      width        : 100%;
      margin-bottom: 10px;
    }
  }

  .signup .description p {
    padding: 0 6%;
  }

  #iletisim .flexSection {
    flex-direction: column;

    .right {
      width: 100%;
    }
  }

  .signup .items {
    flex-wrap      : wrap;
    justify-content: center;

    .item {
      width        : 48%;
      margin-bottom: 30px;
    }
  }

  .header {
    height: 71px;
  }

  .headerArea {
    height: 71px !important;
  }

  .mobileMenu {
    top: 0;
  }

  .mobileMenu.menuActive {
    opacity   : 1;
    visibility: visible;

    &:before {
      width: 100%;
    }

    ul {
      opacity         : 1;
      transition-delay: 0.3s;
    }
  }

  .header--menu {
    display: none !important;
  }

  .header--right {
    display: block !important;

    button {
      background: transparent;
      border    : none;
      box-shadow: none;
    }

    svg {
      * {
        transition: all 0.3s ease-in-out;
      }
    }

    .activeMenu {
      #menuIcon1 {
        transform: translate(333px, 22px) rotate(45deg);
      }

      #menuIcon2 {
        transform: translate(360px, 29px);
      }

      #menuIcon3 {
        transform: translate(331px, 36px) rotate(-45deg) scaleX(1.5);
      }
    }
  }

  .main--back {
    min-height: 237px;
    max-height: 77vh;
  }

  .cookie-policy {
    display: none;
  }

  .services {
    padding-top   : 60px;
    padding-bottom: 60px;
  }

  .about {
    padding-bottom: 50px;

    p {
      font-size: 15px;
    }
  }

  footer {
    padding-top   : 50px;
    padding-bottom: 2px;
    border-bottom : 10px solid #e52628;

    .columns {
      flex-direction: column;

      .column {
        width         : 100% !important;
        border        : none !important;
        align-items   : center;
        display       : flex;
        flex-direction: column;
        margin-bottom : 10px;

        address {
          text-align: center;
          margin    : 0 auto;
          margin-top: 10px;
        }

        .box {
          margin-top: 16px;
        }

        h5 {
          font-size: 18px;

          &:after {
            left     : 50%;
            transform: translateX(-50%);
          }
        }

        p {
          text-align: center;
          margin    : 0 auto;
          margin-top: 10px;
        }

        .socials {
          margin    : 0 auto;
          width     : 35%;
          margin-top: 25px;
        }

        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .title {
    margin-bottom: 14px !important;

    p {
      font-size : 14px !important;
      text-align: center !important;
    }

    h5 {
      font-size  : 18px !important;
      text-align : center !important;
      line-height: 1.5em !important;
    }

    &:after {
      left     : 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  .description {
    p {
      text-align: center;
      margin    : 0 auto;
    }
  }

  h3.shadowText {
    font-size  : 121px !important;
    line-height: 1em !important;
  }

  .services.second {
    .hiddenDesktop {
      display       : block !important;
      width         : 100%;
      margin-bottom : 30px;
      position      : relative;
      padding-bottom: 18px;

      &:after {
        content   : "";
        position  : absolute;
        bottom    : 0;
        left      : 0;
        width     : 80px;
        height    : 3px;
        background: #e52628;
      }

      p {
        font-size     : 14px !important;
        font-weight   : 500;
        font-stretch  : normal;
        font-style    : normal;
        line-height   : 1.5 !important;
        letter-spacing: 0.69px !important;
        text-align    : center;
        color         : #aeaeae;
        margin-bottom : 4px;
      }

      h5 {
        max-width     : 304px;
        margin        : 0 auto;
        font-size     : 18px !important;
        font-weight   : bold;
        font-stretch  : normal;
        font-style    : normal;
        line-height   : 1.39 !important;
        letter-spacing: 0.88px !important;
        text-align    : center;
        color         : #303031;
      }
    }

    .item {
      flex-direction: column;

      .left {
        width: 100%;
        order: 2;

        >.title {
          display: none;
        }

        .box.hidden {
          display: none;
        }
      }

      .right {
        margin-bottom: 14px;
        width        : 100%;
        order        : 1;

        img {
          max-height: 300px;
          object-fit: contain;
        }
      }
    }
  }

  .services.second .item .boxes {
    margin-top: 5px !important;
  }

  .services.second .item .left .boxes .box {
    border       : none !important;
    margin-bottom: 0;
  }

  .services.second .item .right:before {
    left  : 17%;
    top   : 6%;
    width : 45%;
    height: 78%;
  }

  .danismanlik {
    .item {
      flex-direction: column;

      .left {
        width: 100%;
        order: 2;
      }

      .right {
        width        : 100%;
        order        : 1;
        margin-bottom: 14px;

        img {
          max-height: 300px;
          object-fit: contain;
        }
      }
    }
  }

  .widgetBox {
    padding-bottom: 47px;

    .flexSection {
      flex-direction: column;

      .left {
        width: 100%;
        order: 2;

        .btnAll {
          width     : 189px;
          height    : 39px;
          font-size : 15px;
          margin    : 0 auto;
          margin-top: 30px;
        }
      }

      .right {
        width : 100%;
        height: 300px;
        order : 1;
      }
    }
  }

  .services .item .boxes .box .img {
    height: 50px;
    width : auto;
  }

  .sliderTexts {
    h5 {
      font-size: 14px;
      max-width: 366px;
      padding  : 0 4%;
    }

    h2 {
      max-width  : 463px;
      padding    : 0 4%;
      font-size  : 25px;
      line-height: 1.25em;
    }

    a {
      font-size : 15px !important;
      padding   : 0 4%;
      background: transparent !important;
      border    : 1px solid #fff !important;
      color     : #fff !important;
      width     : 184px !important;
      height    : 39px !important;
    }
  }



  .services {
    &:first-child {
      .right {
        display: none;
      }
    }

    .item {
      .boxes {
        margin-top: 33px;
      }

      .btnAll {
        width     : 100%;
        height    : 39px;
        font-size : 15px;
        margin    : 0 auto;
        margin-top: 11px;
      }

      .right {
        width: 100%;
      }

      .left {
        width: 100%;

        .title {
          p {
            text-align: center;
            max-width : 264px;
            margin    : 0 auto;
          }

          h5 {
            text-align: center;
          }

          &:after {
            left     : 50%;
            transform: translateX(-50%);
          }
        }

        .description {
          p {
            text-align: center;
          }
        }

        .boxes {
          .box {
            margin-bottom : 44px;
            padding       : 23px 17px;
            flex-direction: column;
            width         : 100%;
            background    : #fff;
            border        : 0.5px solid #ccc;
            align-items   : center;

            .title {
              margin-top   : 10px;
              margin-bottom: 10px;

              h5 {
                text-align: center;
              }
            }

            .body {
              margin: 0;

              p {
                max-width: 305px;
              }
            }
          }
        }
      }
    }
  }

  .plain {
    padding-top: 1%;

    .items .item {
      .detail {
        padding       : 20px 32px;
        padding-bottom: 37px;
        border-radius : 4px;
        background    : #fff;
        box-shadow    : 0px 3px 15px rgba(0, 0, 0, 0.16);

        .body {
          margin-top: 7px;
        }
      }
    }
  }
}

.cta {
  width: 100%;
  text-align: center;
  display: block;
  background-color: #f7f7f7;
  padding: 5em 0px 5em 0px;

  .cta--title {
    width: 30em;
    height: 3em;
    font-weight: bold;
    font-size: 3em;
    letter-spacing: 0.05em;
    line-height: 1.2em;
    text-align: center;
    color: #303031;
    margin: auto;

  }

  .cta--description {
    font-size: 2em;
    width: 50%;
    margin: auto;
    padding: 0px 0px 2em 0px;
  }

  .cta--link {
    width          : 295px;
    height         : 64px;
    border-radius  : 4px;
    background     : #e52628;
    box-shadow     : 0px 3px 15px rgba(0, 0, 0, 0.16);
    font-family    : "Poppins", sans-serif;
    font-weight    : 600;
    font-size      : 17px;
    letter-spacing : 0.05em;
    text-align     : center;
    color          : #fff;
    display        : flex;
    align-items    : center;
    justify-content: center;
    transition     : all 0.3s ease-in-out;
    margin: auto;
    &:hover {
      background: #fff;
      color     : #e52628;
    }
  }
}